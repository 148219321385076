<template>
    <div>
        <top-nav></top-nav>
        <header-guide :keyword="searchFields.keyword"></header-guide>
        <bread-crumb></bread-crumb>
        <main
            class="search-main search_main"
            v-loading="loading"
            element-loading-text="加载中"
            element-loading-spinner="el-icon-loading"
            style="background:#f5f5f5;"
        >
            <div class="container">
                <div class="employer-panel">
                    <div class="employer-hd new_employer_hd">
                        <dl class="filter-items">
                            <dt>服务分类：</dt>
                            <dd>
                                <el-checkbox-group
                                    v-model="abilityValue"
                                    @change="tagCboxChange"
                                >
                                    <el-checkbox
                                        class="el_checkbox"
                                        v-for="(item,index) in tagListData"
                                        :key="index"
                                        :label="item.name"
                                    >{{item.name}}</el-checkbox>
                                </el-checkbox-group>
                            </dd>
                        </dl>
                        <dl class="filter-items">
                            <dt>项目状态：</dt>
                            <dd>
                                <el-checkbox-group
                                    v-model="taskStatusValue"
                                    @change="taskStatusEvent"
                                >
                                    <el-checkbox
                                        v-for="(item,index) in taskStatus"
                                        :key="index"
                                        :label="item.value"
                                    >{{item.labelTxt}}</el-checkbox>
                                </el-checkbox-group>
                            </dd>
                        </dl>
                        <dl class="filter-items">
                            <dt>项目类型：</dt>
                            <dd>
                                <el-checkbox-group
                                    v-model="taskTypeValue"
                                    @change="taskTypeEvent()"
                                >
                                    <el-checkbox
                                        v-for="(item,index) in taskType"
                                        :key="index"
                                        :label="item.value"
                                    >{{item.labelTxt}}</el-checkbox>
                                </el-checkbox-group>
                            </dd>
                        </dl>
                        <dl class="filter-items">
                            <dt>报名资格：</dt>
                            <dd>
                                <el-checkbox-group
                                    v-model="condValue"
                                    @change="condDataEvent()"
                                >
                                    <el-checkbox
                                        v-for="(item,index) in condData"
                                        :key="index"
                                        :label="item.value"
                                    >{{item.labelTxt}}</el-checkbox>
                                </el-checkbox-group>
                            </dd>
                        </dl>
                        <dl class="filter-items mission-budget">
                            <dt class="name">项目预算：</dt>
                            <dd class="box">
                                <span style="float:left;"></span>
                                <el-checkbox
                                    class="checkbox"
                                    v-model="missionBudgetCheckbox"
                                    @change="remunerationClear"
                                >全部</el-checkbox>
                                <div class="filter-price">
                                    <el-input
                                        class="price start-price"
                                        type="text"
                                        v-model="searchFields.min_money"
                                        placeholder="请输入价格"
                                    ></el-input>
                                    <span class="price-tag">~</span>
                                    <el-input
                                        class="price"
                                        type="text"
                                        placeholder="请输入价格"
                                        v-model="searchFields.max_money"
                                        @keyup.enter.down="loadTaskData"
                                    ></el-input>

                                    <button
                                        @click="missionBudgetCheckbox=false;loadTaskData()"
                                        type="button"
                                    >确定</button>
                                </div>
                            </dd>
                        </dl>
                    </div>

                    <div class="sort_wrap">
                        <div
                            @click="changeTaskOrder(item.value)"
                            v-for="(item,index) in taskOrder"
                            :key="index"
                            :class="(item.active ? 'active' : '')"
                            style="padding:0 !important"
                        >
                            <span icon="name">{{item.lable}}</span>

                            <span v-if="item.active">
                                <img
                                    class="icon"
                                    v-if="listOrderType=='asc'"
                                    :src="$img.sort_top_hover"
                                />
                                <img
                                    class="icon"
                                    v-if="listOrderType=='desc'"
                                    :src="$img.sort_bottom_hover"
                                />
                            </span>
                        </div>

                        <div class="search-address">
                            <span class="name"></span>
                            <el-checkbox
                                class="checkbox"
                                v-model="addressCheckbox"
                                @change="areaClear"
                            ></el-checkbox>
                            <el-cascader
                                class="cascader"
                                @change="areaChange"
                                :clearable="true"
                                v-model="areaValue"
                                :options="areaListData"
                                placeholder="请选择地址"
                            ></el-cascader>
                        </div>

                        <span class="right-count">
                            共有
                            <span v-text="task_count"></span> 个项目
                        </span>
                    </div>

                    <div
                        class="task-list-wraper"
                        v-if="taskListData && taskListData.length>0"
                    >
                        <article
                            class="item-box"
                            style="height:220px;overflow:hidden;"
                            v-for="(task,index) in taskListData"
                            :key="index"
                            @click="goDetail1(task.tk)"
                        >
                            <header class="item-hd">
                                <!-- 以下状态待完善============================================ -->
                                <span
                                    v-if="task.status==3"
                                    class="badget"
                                    :style="{'backgroundColor':'#587ddc'}"
                                    v-text="task.status_value"
                                ></span>

                                <span
                                    v-if="task.status>3 && task.status<8"
                                    class="badget"
                                    :style="{'backgroundColor':'#f59b15'}"
                                >进行中</span>

                                <span
                                    v-if="task.status==8"
                                    class="badget"
                                    :style="{'backgroundColor':'#8c8c8c'}"
                                >已完成</span>

                                <span
                                    v-if="task.status==9"
                                    class="badget"
                                    :style="{'backgroundColor':'#f59b15'}"
                                >调解中</span>

                                <span
                                    v-if="task.status==10"
                                    class="badget"
                                    :style="{'backgroundColor':'#8c8c8c'}"
                                >已结束</span>

                                <h3 class="tit">{{ task.task_name }}</h3>

                                <div class="desc-box">
                                    <div class="des-item">
                                        <div class="time-box">
                                            <span class="desc-tit el-icon-time time-tit"></span>
                                            <span
                                                class="num"
                                                v-text="task.approve_time"
                                            ></span>
                                        </div>
                                        <div class="sign-num-box">
                                            <span
                                                class="desc-tit"
                                                style="margin-left: 30px;"
                                            >报名人数：</span>
                                            <span class="num">{{task.enroll_count}}</span>
                                            <span>人</span>
                                        </div>
                                        <div class="num-box">
                                            <!-- <span>酬金：</span> -->
                                            <span class="num">￥{{task.remuneration}}</span>
                                        </div>
                                    </div>
                                </div>
                            </header>

                            <div class="item-bd">
                                <div
                                    class="media-l"
                                    fit="cover"
                                    v-if="task.employer"
                                >
                                    <el-image
                                        :src="util.reImg(task.employer.avatar)"
                                        alt="雇主头像"
                                    >
                                        <div
                                            slot="error"
                                            class="image-slot"
                                        >
                                            <span class="default-img"></span>
                                        </div>
                                    </el-image>
                                </div>
                                <div class="media-r">
                                    <div class="media-desc-item">
                                        <span class="desc">{{task.employer_name}}</span>
                                        <span class="desc">项目类别：{{task.task_type_value}}</span>
                                    </div>
                                    <div class="media-desc-item">
                                        <div
                                            class="desc"
                                            v-if="task.employer"
                                        >
                                            信用评分：
                                            <el-rate
                                                :value="parseFloat(task.employer.credence)"
                                                disabled
                                                :allow-half="true"
                                                disabled-void-color="#C6D1DE"
                                                show-score
                                                text-color="#f59b15"
                                                score-template="{value}"
                                            ></el-rate>
                                        </div>
                                        <span class="desc">服务分类：{{task.speciality_type_value}}</span>
                                    </div>
                                </div>
                            </div>
                        </article>
                    </div>
                    <div
                        class="employer-panel no-data-panel"
                        v-else
                        style="margin-top:100px;"
                    >
                        <div class="employer-hd no-data-hd">
                            <p>没有更多相关项目了，看看为您推荐的项目吧~~</p>
                        </div>
                    </div>
                    <div
                        class="header-box"
                        style="height: 60px;line-height: 60px;"
                        v-if="hot_task && hot_task.length>0"
                    >
                        <div
                            class="left"
                            style="float: left;"
                        >
                            <h3
                                class="title"
                                style="padding: 0;margin: 0;font-size: 20px;font-weight: 400;float: left;height: 60px;line-height: 60px;display: inline-block;"
                            >为您推荐</h3>
                            <span
                                class="desc"
                                style="float: left;height: 60px;line-height: 72px;display: inline-block;font-size: 14px;color: #999;margin-left: 10px;"
                            >靠谱项目，交付有保障</span>
                        </div>
                        <div
                            class="right more"
                            style="float: right;font-size: 16px;"
                        ></div>
                    </div>

                    <div
                        class="task-list-wraper"
                        v-if="hot_task && hot_task.length>0"
                    >
                        <article
                            class="item-box"
                            style="height:260px;overflow:hidden;"
                            v-for="(task,index) in hot_task"
                            :key="index"
                            @click="goDetail1(task.tk)"
                        >
                            <header class="item-hd">
                                <!-- 以下状态待完善============================================ -->
                                <!-- 报名中 -->
                                <span
                                    v-if="task.status==3"
                                    class="badget"
                                    :style="{'backgroundColor':'#587ddc'}"
                                    v-text="task.status_value"
                                ></span>

                                <!-- 进行中 -->
                                <span
                                    v-if="task.status>3 && task.status<8"
                                    class="badget"
                                    :style="{'backgroundColor':'#4b9bc4'}"
                                >进行中</span>

                                <span
                                    v-if="task.status==8"
                                    class="badget"
                                    :style="{'backgroundColor':'#8c8c8c'}"
                                >已完成</span>

                                <span
                                    v-if="task.status==10"
                                    class="badget"
                                    :style="{'backgroundColor':'#4b9bc4'}"
                                >已结束</span>

                                <h3 class="tit">{{ task.task_name }}</h3>

                                <div class="desc-box">
                                    <div class="des-item">
                                        <div class="time-box">
                                            <span class="desc-tit el-icon-time time-tit"></span>
                                            <span
                                                class="num"
                                                v-text="task.approve_time"
                                            ></span>
                                        </div>
                                        <div class="sign-num-box">
                                            <span class="desc-tit">报名人数：</span>
                                            <span class="num">{{task.enroll_count}}</span>
                                            <span>人</span>
                                        </div>
                                        <div class="num-box">
                                            <!-- <span>酬金：</span> -->
                                            <span class="num">￥{{task.remuneration}}</span>
                                        </div>
                                    </div>
                                </div>
                            </header>

                            <div class="item-bd">
                                <div
                                    class="media-l"
                                    fit="cover"
                                    v-if="task.employer"
                                >
                                    <el-image
                                        :src="util.reImg(task.employer.avatar)"
                                        alt="雇主头像"
                                    >
                                        <div
                                            slot="error"
                                            class="image-slot"
                                        >
                                            <span class="default-img"></span>
                                        </div>
                                    </el-image>
                                </div>

                                <div class="media-r">
                                    <div class="media-desc-item">
                                        <span class="desc">{{task.employer_name}}</span>
                                        <span class="desc">
                                            项目类别：
                                            <span
                                                v-if="task.task_type==1"
                                                style="color:#333;font-size:14px;border-radius:3px;"
                                            >{{task.task_type_value}}</span>

                                            <span
                                                v-if="task.task_type==2"
                                                style="color:#333;font-size:14px;border-radius:3px;"
                                            >{{task.task_type_value}}</span>

                                            <span
                                                v-if="task.task_type==3"
                                                style="color:#333;font-size:14px;border-radius:3px;"
                                            >{{task.task_type_value}}</span>
                                        </span>
                                    </div>

                                    <div class="media-desc-item">
                                        <div
                                            class="desc"
                                            v-if="task.employer"
                                        >
                                            信用评分：
                                            <el-rate
                                                :value="parseFloat(task.employer.credence)"
                                                disabled
                                                :allow-half="true"
                                                disabled-void-color="#C6D1DE"
                                                show-score
                                                text-color="#f59b15"
                                                score-template="{value}"
                                            ></el-rate>
                                        </div>
                                        <span class="desc">服务分类：{{task.speciality_type_value}}</span>
                                    </div>
                                </div>
                            </div>
                        </article>
                    </div>

                    <pagination
                        v-if="taskListData.length>0"
                        :pageSize="pageInfo.limit"
                        :currentPage="pageInfo.page"
                        :pageTotal="pageInfo.total"
                        @current-change="newPage"
                    ></pagination>
                </div>
            </div>
        </main>
        <global-footer></global-footer>
    </div>
</template>

<script>
import TopNav from "@/components/common/TopNav.vue";
import HeaderGuide from "@/components/common/HeaderGuide.vue";
import BreadCrumb from "@/components/common/BreadCrumb.vue";
import Pagination from "@/components/common/Pagination.vue";
import GlobalFooter from "@/components/common/GlobalFooter.vue";
export default {
    name: "taskList",
    metaInfo: {
        title: "工程项目列表 - 询龙网",
        meta: [
            {
                name: "keywords",
                content: "工程极客,工程技术项目,咨询人才,咨询团队,在家工作，众包平台,工程众包,造价工程,项目测绘,项目评估,工程勘测,工程设计,工程造价,工程监理,财务会计,税务服务,工程法务,技术承包,资料外包,造价工程师,装修设计"
            }, {
                name: "description",
                content: "在这里您可以快速找到优质的工程快包项目"
            }
        ],
        link: [{
            rel: "asstes",
            href: 'https://www.xunlongwang.com/task/list'
        }]
    },
    components: {
        TopNav, //头部吊顶
        HeaderGuide, //头部指南
        BreadCrumb, //面包屑导航
        Pagination, //分页
        GlobalFooter //公共底部
    },

    data() {
        return {
            scoreValue: 4.5, // 雇主信用评分测试===================测试
            taskListData: '', //项目列表数据
            tagListData: '', //标签数据
            areaListData: [], //公共 - 区域

            searchFields: {
                keyword: "", //搜索关键字
                tags: "", //项目分类
                user_type: "", //身份类型
                area: "", //地址
                task_status: 0,
                task_type: 0,
                min_money: "",
                max_money: ""
            },
            abilityValue: ["全部"], //被选中的项目分类值
            loading: false,
            pageInfo: {
                //分页信息
                page: 1,
                limit: 10,
                total: 0
            },

            taskOrderClass: ["jk-icon-jiantoushang", "jk-icon-jiantouxia"],
            taskOrderActive: 0,
            taskOrderActiveClass: 0,
            taskOrdeIsFirst: true,
            listOrder: "", //项目排序字段
            listOrderType: "desc", //项目排序类型字段
            taskStatusValue: [0], //项目状态，默认选中第一个值
            taskTypeValue: [0], //项目类型
            condValue: [0], //项目 - 报名资格
            task_count: 0, //筛选出来的项目数
            areaValue: [], //地址选中值
            //项目 - 排序数据
            taskOrder: [
                {
                    lable: "综合排序",
                    active: true,
                    class: "jk-icon-jiantouxia",
                    value: 1
                },
                {
                    lable: "发布时间",
                    active: false,
                    class: "jk-icon-jiantouxia",
                    value: 2
                },
                {
                    lable: "截止时间",
                    active: false,
                    class: "jk-icon-jiantouxia",
                    value: 3
                },
                {
                    lable: "雇主信用",
                    active: false,
                    class: "jk-icon-jiantouxia",
                    value: 4
                },
                {
                    lable: "项目酬金",
                    active: false,
                    value: 5
                }
            ],
            taskStatus: [
                {
                    labelTxt: "全部",
                    value: 0
                },
                {
                    labelTxt: "报名中",
                    value: 3
                },
                {
                    labelTxt: "进行中",
                    value: 37
                },
                {
                    labelTxt: "已完成",
                    value: 8
                },
                {
                    labelTxt: "已结束",
                    value: 10
                }
            ],
            //项目 - 项目类型
            taskType: [
                {
                    labelTxt: "全部",
                    value: 0
                },
                {
                    labelTxt: "竞价项目",
                    value: 1
                },
                {
                    labelTxt: "比选项目",
                    value: 2
                },
                {
                    labelTxt: "日薪项目",
                    value: 3
                }
            ],
            //项目 - 报名资格
            condData: [
                {
                    labelTxt: "全部",
                    value: 0
                },
                {
                    labelTxt: "公司",
                    value: 3
                },
                {
                    labelTxt: "工作室",
                    value: 2
                },
                {
                    labelTxt: "个人",
                    value: 1
                }
            ],
            //公共 - 区域 - 默认数据
            addressCheckbox: false, //地址checkbox选中值
            missionBudgetCheckbox: true, //项目预算checkbox选中值
            hot_task: []
        };
    },

    created() {
        this.taskListData = [];
        this.getAreaData(); //获取地级市地址
        this.toggleSearch(); //搜索关键字等
    },

    filters: {
        //项目分类格式化后输出
        abilityFilter: function (e) {
            if (e.name == "其他分类") {
                return e.name;
            } else {
                return e.name + "(" + e.total + ")";
            }
        },
        //判断项目证书是否有值格式化输出
        filterAchievement: function (e) {
            if (e.hasOwnProperty("credentials")) {
                if (
                    e.credentials[0] &&
                    e.credentials[0].hasOwnProperty("title")
                ) {
                    return e.credentials[0]["title"];
                }
            } else {
                return "";
            }
        }
    },

    methods: {
        //获取项目列表
        loadTaskData() {
            let _this = this;
            _this.loading = true;
            _this.post(
                "/task/index/lists",
                {
                    page: _this.pageInfo.page, //分页信息
                    limit: _this.pageInfo.limit, //每条显示数
                    order: _this.listOrder, //排序
                    orderType: _this.listOrderType, //排序类型
                    search_fields: _this.searchFields //搜索字符串
                },
                function (res) {
                    _this.loading = false;
                    if (res.code == 200) {
                        _this.task_count = res.data.total;
                        _this.tagListData = res.data.tag_list; //获取标签
                        _this.taskListData = res.data.list; //加载项目列表数据
                        //分页相关
                        _this.pageInfo.total = parseInt(res.data.total);
                        if (_this.pageInfo.total == 0) {
                            _this.loadHotTaskData();
                        } else {
                            _this.hot_task = [];
                        }

                    }
                }
            );
        },

        //获取地级市区域数据
        getAreaData() {
            let _this = this;
            _this.post("/system/area/area", {}, function (res) {
                if (res.code == 200) {
                    _this.areaListData = res.data;
                }
            });
        },

        //分页点击方法
        newPage(page) {
            this.pageInfo.page = page; //切换点击的分页
            this.loadTaskData(); //加载数据
        },

        //项目详情
        goDetail1(tk) {
            this.$router.push("/task/detail?tk=" + tk);
        },
        //加载热门项目
        loadHotTaskData() {
            let _this = this;
            this.post(
                "/task/index/task_list",
                {
                    type: "hot",
                    page: 1,
                    limit: 5
                },
                function (res) {
                    if (res.code == 200) {
                        _this.hot_task = res.data.list;
                    }
                }
            );
        },

        //极客 - 项目分类checkbox改变事件
        tagCboxChange() {
            let _this = this;
            _this.pageInfo.page = 1; //切换到第一页
            _this.abilityValue = _this.abilityValue.filter(c => c != undefined); //去除值为undefined的值
            if (_this.abilityValue.length > 0) {
                if (
                    _this.abilityValue[_this.abilityValue.length - 1] == "全部"
                ) {
                    _this.abilityValue = ["全部"];
                    _this.searchFields.tags = "";
                } else {
                    _this.abilityValue = _this.abilityValue.filter(
                        c => c != "全部"
                    );
                    _this.searchFields.tags = _this.abilityValue.join(",");
                }
                _this.loadTaskData(); //加载极客列表
            } else {
                _this.searchFields.tags = "";
                _this.loadTaskData(); //加载极客列表
            }
        },

        //改变地址事件
        areaChange() {
            let _this = this;
            _this.addressCheckbox = true;
            _this.searchFields.area = _this.areaValue;
            _this.loadTaskData(); //加载极客列表
        },

        //清空地址选择
        areaClear() {
            if (this.areaValue != []) {
                this.areaValue = [];
                this.searchFields.area = [];
                this.loadTaskData(); //加载极客列表
            }
        },

        //清空项目预算
        remunerationClear() {
            if (this.missionBudgetCheckbox == true) {
                this.searchFields.min_money = "";
                this.searchFields.max_money = "";
                this.loadTaskData();
            }
        },

        //项目 - 项目排序
        changeTaskOrder(value) {
            this.listOrder = value;
            let index = value - 1;

            this.taskOrder.forEach((v, k) => {
                this.taskOrder[k].active = false;
            });

            this.taskOrder[index].active = true;

            if (this.taskOrderActive == index) {
                if (this.taskOrdeIsFirst) {
                    this.taskOrderActiveClass = 0;
                    this.taskOrdeIsFirst = false;
                } else {
                    this.taskOrderActiveClass = 1 - this.taskOrderActiveClass;
                }
                this.taskOrder[index].class = this.taskOrderClass[
                    this.taskOrderActiveClass
                ];
            } else {
                this.taskOrderActive = index;
                this.taskOrderActiveClass = 1;
            }

            this.listOrderType = this.taskOrderActiveClass ? "desc" : "asc";
            this.loadTaskData();
        },

        //项目 - 项目状态
        taskStatusEvent() {
            if (this.taskStatusValue[this.taskStatusValue.length - 1] == 0) {
                this.taskStatusValue = [0];
                this.searchFields.task_status = "";
            } else {
                this.taskStatusValue = this.taskStatusValue.filter(c => c != 0); //把全部除掉
                this.searchFields.task_status = this.taskStatusValue.join(","); //赋值搜索项
            }
            this.loadTaskData();
        },

        //项目 - 项目类型
        taskTypeEvent() {
            if (this.taskTypeValue[this.taskTypeValue.length - 1] == 0) {
                this.taskTypeValue = [0];
                this.searchFields.task_type = "";
            } else {
                this.taskTypeValue = this.taskTypeValue.filter(c => c != 0); //把全部除掉
                this.searchFields.task_type = this.taskTypeValue.join(","); //赋值搜索项
            }
            this.loadTaskData();
        },

        //项目 - 报名资格
        condDataEvent() {
            if (this.condValue[this.condValue.length - 1] == 0) {
                this.condValue = [0];
                this.searchFields.user_type = ""; //搜索项清空
            } else {
                this.condValue = this.condValue.filter(c => c != 0); //把全部除掉
                this.searchFields.user_type = this.condValue.join(","); //赋值搜索项
            }
            this.loadTaskData();
        },

        //根据路由切换搜索极客还是项目
        toggleSearch() {
            //标签搜索
            if (this.$route.query.tag) {
                this.abilityValue = [this.$route.query.tag]; //标记选中项
                this.searchFields.tags = this.$route.query.tag; //赋值搜索标签
            }

            //关键字搜索
            this.searchFields.keyword = this.$route.query.keyword;
            this.loadTaskData(); //刷新数据
        }
    },

    watch: {
        $route: {
            handler: function (val, oldVal) {
                this.toggleSearch();
            },
            // 深度观察监听
            deep: true
        }
    }
};
</script>

<style lang="less">
@import "../../styles/search.less";
.search_main {
    .el-tabs__nav-scroll {
        width: 1180px;
        margin: 0 auto;
        height: inherit;
    }
}
._score_title {
    float: left;
}
._score {
    float: left;
    width: 100px;
    .el-rate__item {
        width: 23px !important;
    }
}
._geekType_ {
    width: 100px;
    overflow: hidden;
    height: 16px;
}
.sort_wrap {
    height: 48px;
    line-height: 46px;
    margin-top: 20px;
    background: #fafafa !important;
    border: 1px solid #eae9e6;
    color: #666;
    div {
        float: left;
        display: block;
        width: 108px;
        height: 100%;
        text-align: center;
        cursor: pointer;
        border-right: 1px solid #eae9e6;
        .icon {
            vertical-align: middle;
        }
    }
    div.active {
        border-right: 1px solid #eae9e6;
        border-left: 1px solid #eae9e6;
        color: #587ddc !important;
        background: #f5f5f5;
        margin-left: -1px;
    }

    ._vline_ {
        border-right: 1px solid #eae9e6;
        width: 1px;
        height: 35px;
        margin-top: 5px;
    }
}

.new_employer_hd {
    width: 1200px;
    padding: 20px;
    padding-bottom: 10px !important;
    background: #fff;
    .el-checkbox {
        padding: 0px;
        margin: 0px;
        padding-top: 4px !important;
        margin-left: 10px;
    }
    .filter-items {
        padding: 0px;
        margin: 0px;
        border: none;
        margin-bottom: 10px;
    }
    .filter-items dd {
        padding: 0px;
        margin: 0px;
        width: 1050px !important;
        line-height: 16px;
    }
    .filter-items dt {
        width: 76px;
    }
    .el-checkbox-group {
        padding: 0px;
        margin: 0px;
    }
    .el-cascader {
        height: 27px !important;
    }

    .mission-budget .price .el-input__inner::-webkit-input-placeholder {
        color: #888;
    }
}
</style>




